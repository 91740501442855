<template>
    <OwnerDisputes/>
</template>
<script>
import OwnerDisputes from '@/components/Wire/OwnerDisputes'
export default {
    components:{
        OwnerDisputes
    }
}
</script>